@import '~bootstrap/dist/css/bootstrap.min.css';
.status-image-parent-modified {
    margin-top: 17px !important;
    margin-bottom: 20px !important;
    margin-right: 7px !important;
    overflow: hidden;
}

.status-image-custom-align {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.step-4 .status-image {
    /* background-image: url('../images/out_for_delivery_irregularity_return.png'); */
    background-image: url('../images/delivered_active.svg');
}

.step-4-post-point-pickup .status-image {
    /* background-image: url('../images/out_for_delivery_irregularity_return.png'); */
    background-image: url('../images/post_point_active.svg');
}

.step-5-picked-up-in-post-office .status-image {
    /* background-image: url('../images/out_for_delivery_irregularity_return.png'); */
    background-image: url('../images/delivered_active.svg');
}

.step-1 .status-image {
    /* background-image: url('../images/icon_parcel maken_red RGB.png'); */
    background-image: url('../images/icon_parcel maken_red RGB_new.svg');
}

.step-2 .status-image {
    background-image: url('../images/processing_active.svg');
}

.step-3 .status-image {
    /* background-image: url('../images/out_for_delivery_irregularity_return.png'); */
    background-image: url('../images/out_for_delivery_active_new.svg');
}

.language-selection-li {
    cursor: pointer;
}

.step-strong {
    font-weight: bold;
}

.step-strong-sustainability{
  font-weight: bold;
  color: #66a367 !important;
}


.custom-height {
    height: 48px;
}

.category-spacing-custom {
    margin-bottom: 25px;
}

.custom-padding-faq-title {
    padding-bottom: 0px !important;
}

.custom-padding-faq-question {
    padding-top: 25px;
}

.custom-title-color {
    color: #a0a2a3;
}

.custom-padding-title {
    padding-bottom: 15px;
}

.custom_hand_pointer {
    cursor: pointer;
}

.exception-banner p {
    margin-top: 10px;
}

.commercial_sender_custom {
    font-size: 25px;
    font-family: Ubuntu;
}

.remove-padding-home {
    padding: 0px;
}

.custom-progress-min-height {
    min-height: 196px;
}

.custom-progress .w3-container {
    width: 33%;
    float: left;
    margin-right: 15px;
    margin-bottom: 40px
}

.dynalogic-text-color {
    color: #5a5a5f;
}

.irregularity-text-key {
    color: #f22230;
}

.items-faqs-font {
    font-family: Ubuntu !important;
}

.custom-faq-chevron {
    margin-top: 0 !important;
}

.safePlaceCustom {
    width: auto;
    height: 100%;
    float: right;
    max-height: 550px;
}

.pickup-point-details {
    color: #f22230;
    font-weight: bold;
    cursor: pointer;
}

.pickup-point-details i {
    float: right;
}

.pickup-point-details-spacing {
    margin-top: 15px;
    /* margin-bottom:15px; */
}

.pickup-point-details-spacing img {
    margin-right: 15px
}

.sundayAlignment {
    float: right;
}

.opening-hours-minus {
    padding-right: 12px;
}

.back-to-summary-spacing {
    padding-top: 7px;
}

.back-to-summary-spacing-mobile {
    padding-bottom: 12px;
}

.secondary-info-zone-spacing {
    margin: 0 auto;
    padding: 0;
}

.pickup-point-alignment {
    margin-bottom: 20px;
}

#mybpostbanner {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 105px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-color: rgb(239, 38, 55);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-top-color: rgb(239, 38, 55);
    border-top-style: solid;
    border-top-width: 1px;
    font-family: Ubuntu, Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: rgb(160, 162, 163);
    background: #f2f2f2;
    z-index: 1000;
}

#mybpostleft {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    width: 105px;
    min-width: 105px;
    height: 70px;
}

#mybpostclose {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAIAAABvFaqvAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAASdEVYdFNvZnR3YXJlAEdyZWVuc2hvdF5VCAUAAAJySURBVDhPhdTLTipBEMbxef+ncOOClQvixo0PIMJRQFFQvIA3VBATY0j0nB/UnGYcRb5EHbu6/v1V9SWbFvTy8jKZTJ6fn0ej0f39/XA4vL6+vrq68nswGNzc3Bh8fHw0wTST87SFliCB8XgMcXt7e3l5eXp62m63Dw4O/ix0eHh4dHTU6/VwA2dykZWDwogZFj85OZEpzbfBpIuLC1xEOGYfHh6KrDkoUfr9fqvVqtVqFvy7Qszu7e0dHx+zVmRl/iSK1diZzWZ50gq9vr42m82wrBXRr8wf/8Dz0u1287nr9PHxoUy+1Mg+SMaO7jLSaDSETeJ8e3tbLHKK6nQ6W1tbdtC3cvb398/OzlSjwEyduqi7iDG7Wq1ubGxUKpUSyyYaJ15iRKI6FMhUdnd3x46lIkbyUUqsRNnd3f38/IxBFagjTGWKNEl1EQuVWD9SQhwQNxlj6ipVQYm1ubm5ikJOg2W4yXzV6/W3t7c8UhBWouzs7HynkHujTWtAqSIq9quoJUhpduH7pEThpdivPPxffDiZcHmz/c4jC5W6m/r1nWXHad5sOxcXPY8sNqJIicEiy1GMQdvvTJ6fnzuMmfvBno1DjbCzW6KEEktyjIQDB31+IOOKGNLy9/d3YQFd+3GPsFBcWt8Sv1wRMTwt1zPVRsJaeSEUYb42Pz09gcyfETxFKtBGshq+fpG0uPq8xzOSP2zpSYq9VKN18qSv0l2tVREvQVk+bH4Siy9hr5IXjnP1OxbGSe2SXVGujVupSKHy469fts8752FmXhooKdmxgLAS79GXRKEliASErQ9ntt3kSCb58K9BIeuVKNPp9B+BRhiF7nNjRgAAAABJRU5ErkJggg==');
    background-size: 24px 24px;
}

#mybposticon {
    width: 70px;
    height: 70px;
    min-width: 70px;
    background-image: url('../images/bpost-tile.svg');
    background-size: 70px 70px;
}

#mybpostcenter {
    text-align: left;
    min-width: 80px;
    width: 100%;
}

#mybpostright {
    white-space: nowrap;
    cursor: pointer;
    background-color: rgb(239, 38, 55);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-bottom: 5px;
    padding-top: 4px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
    font-weight: 700;
    margin-left: 15px;
    margin-right: 15px;
}

#mybpostright a {
    color: white;
}

.faq-sub-category-padding-custom {
    padding-left: 15px;
}

i.more-less.glyphicon.glyphicon-chevron-right,
i.more-less.glyphicon.glyphicon-chevron-down {
    float: right;
    margin-top: 8px;
}

#info {
    border-radius: 4px;
    border: solid 1px #bce8f1;
    background-color: #d9edf7;
    padding: 15px;
    text-align: left;
    margin-top: 15px;
    font-family: ubuntu;
}

#info p {
    color: #a0a2a3;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}

#info h3 {
    color: #31708f;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 5px;
}

#track_popup .modal-footer #info {
    margin-top: 15px;
}

.notification-banner-mobile {
    /* width: 1440px; */
    background-color: #4195b9;
    padding-top: 20px;
}

.notification-banner {
    background-color: #4195b9;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 9%;
    padding-right: 3%;
    height: auto;
    width: 100%;
    color: #ffffff !important;
    font-family: Ubuntu-medium;
    font-size: 16px;
    text-emphasis: left;
}

@media screen and (max-width: 992px) {
    .notification-banner {
        padding-left: 2%;
    }
}


/* #warning{
	border-radius: 4px;
	border: solid 1px #faebcc;
	background-color: #fcf8e3;
	padding: 15px;
	text-align: left;
	margin-top: 15px;
}

#warning p{
	color: #a0a2a3;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0px;
}

#warning h3{
	color: #8a6d3b;
	font-size: 24px;
	margin-top: 0;
    margin-bottom: 5px;
} */

#track_popup .modal-footer #warning {
    margin-top: 15px;
}

#alert {
    border-radius: 4px;
    border: solid 1px #ef2637;
    background-color: #f3f3f3;
    padding: 15px;
    text-align: left;
    margin-top: 15px;
}

#alert p {
    color: #a0a2a3;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}

#alert h3 {
    color: #ef2637;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 5px;
}

#track_popup .modal-footer #alert {
    margin-top: 15px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.multiple_barcode .count_shipment_p {
    /* width: 605px;
	 height: 36px; */
    font-family: 'Ubuntu-Light';
}

.custom-header-bpost .custom_breadcrumb .Track-parcel a {
    font-family: 'Ubuntu';
    /*font-size: 14px;
	font-weight: 'normal';
	font-style: 'normal';
	font-stretch: normal; */
}

.custom-header-bpost li.breadcrumb-item.parcel-number {
    font-family: 'Ubuntu-Regular';
    /*
    width: 250px;
    height: 20px;
    font-size: 14px;

    font-weight: 'normal';
    font-style: 'normal';
    letter-spacing: 'normal';
    color: #5a5a5f; */
}

.multiple_barcode .count_shipment_p {
    font-family: 'Ubuntu-Light';
    /*height: 36px;
	width: 605px;
	font-style: 'normal';
	font-stretch: normal;
	letter-spacing: 'normal'; */
}

.custom-multiple-parcel p {
    font-family: 'Ubuntu-Medium';
    /*height: 24px;
	width: 315px;
	font-weight: 500;
	font-style: 'normal';
	font-stretch: 'normal';
	line-height: 1.2;
	letter-spacing: 'normal'; */
}

.custom-view-detail-a,
.custom-view-detail-a:hover {
    font-family: 'Ubuntu-Regular';
    color: #f22230;
    text-align: 'center';
    /*
	font-size: 14px;
	height: 20px;
	width: 70px;

	font-weight: 'normal';
	font-style: 'normal';
	font-stretch: 'normal';
	line-height: 1.43;
	letter-spacing: 'normal';
	*/
    text-decoration: none;
}

.custom_status_parent .parceln {
    font-family: 'Ubuntu-Regular';
    color: #f22230;
    /*
	font-size: 14px;
	height: 20px;

	width: 605px;
	font-weight: 'normal';
	font-style: 'normal';
	font-stretch: 'normal';
	line-height: 1.43;
	letter-spacing: 'normal'; */
}

.custom_status_parent .DeliveryInfo {
    font-family: 'Ubuntu-medium';
    font-size: 24px;
    font-weight: 500;
}

.custom_status_parent .heading {
    font-family: 'Ubuntu-regular';
    font-size: 30px;
    /*height: 40px;
	width: 605px;
	font-weight: 500;
	font-style: 'normal';
	font-stretch: 'normal';
	letter-spacing: 'normal'; */
}

.custom-submit-button button {
    font-family: 'Ubuntu-Medium';
    /*height: 24px;
	width: 403px;
	font-style: 'normal';
	font-stretch: 'normal';
	letter-spacing: 'normal'; */
}

p.panel-parent-div {
    font-family: 'Ubuntu-Regular';
    color: #f22230;
}

p.panel-parent-div_1 {
    margin-bottom: 0;
}

.panel-sub-div {
    font-family: 'Ubuntu-Regular';
}

.custom-address-info .deliver-to {
    font-family: 'Ubuntu-Regular';
    color: #5a5a5a;
}

.custom-address-info .deliver-to-address {
    font-family: 'Ubuntu-Medium';
}

.custom-address-info .deliver-to-pref {
    font-family: 'Ubuntu';
}

.custom-address-info .deliver-to-info {
    font-family: 'Ubuntu-Regular';
}

.custom-submit-button {
    font-family: 'Ubuntu-Medium';
}

.custom-neighbour-parent .custom-pref-two,
.custom-pickup-parent .custom-pref-two,
.custom-safe-parent .custom-pref-two {
    font-family: 'Ubuntu';
}

.custom-neighbour-parent .custom-pref-one,
.custom-pickup-parent .custom-pref-one,
.custom-safe-parent .custom-pref-one {
    font-family: 'Ubuntu';
}

.custom-address-info .deliver-to-address {
    font-family: 'Ubuntu';
}

.custom-deliver-kariboo p.deliver-to-get-direction,
.custom-deliver-kariboo p.deliver-to-open-hours {
    font-family: 'Ubuntu';
}

.custom-deliver-kariboo .opening-hours-body.show-div {
    font-family: 'Ubuntu';
}

.pickup_point_deliver {
    font-family: 'Ubuntu-Regular';
    /*color: #5a5a5a;*/
    line-height: 1.43;
}

.usabilla {
    background: #F2F3F5;
    padding: 0 19px;
    color: #77767A;
    min-height: 100px;
    border: 1px solid #EAEAEA;
    font-weight: 600
}

.custom_status_parent .estimated-date {
    font-family: Ubuntu;
    font-size: 14px;
}

.custom_status_parent .info {
    font-size: 14px;
    font-family: Ubuntu;
}

.open-button {
    width: 169px;
    height: 60px;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
    background-color: #da1021;
    cursor: pointer;
    bottom: 23px;
    float: right;
    margin-bottom: 15px;
    margin-right: 15px;
    outline: none;
    /* color: white;
	border: none;
	cursor: pointer;
	bottom: 23px;
	float: right;
	width: 232px;
	height: 48px;
	border-radius: 4px;
	background-color: #da1021;
	margin-bottom: 15px;
	margin-right: 15px; */
}

.open-button-mobile {
    display: block;
    /* max-width: 300px; */
    color: white;
    border-radius: 8px;
    cursor: pointer;
    bottom: 23px;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background-color: #da1021;
    margin-bottom: 20px;
    margin-right: 20px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
}

.payment-method .glyphicon-chevron-right:before,
.opening-hours .glyphicon-chevron-right:before,
.get-direction .glyphicon-chevron-right:before {
    width: 14px;
    height: 18px;
    /* -webkit-mask-image: url(../images/chevron-right.svg) no-repeat 50% 50%;
    mask-image: url(../images/chevron-right.svg);
    -webkit-mask: url(../images/chevron-right.svg) no-repeat 50% 50%; */
    background-image: url('../images/chevron-right.png');
    background-size: 100% 50%;
    background-repeat: no-repeat;
    display: block;
    content: " ";
    -webkit-mask-size: contain;
}

.opening-hours .glyphicon-chevron-down:before {
    width: 14px;
    height: 18px;
    /* -webkit-mask-image: url('../images/chevron-down.svg') no-repeat 50% 50%;
    mask-image: url('../images/chevron-down.svg');
    -webkit-mask: url('../images/chevron-down.svg') no-repeat 50% 50%; */
    background-image: url('../images/chevron-down.png');
    background-size: 90% 90%;
    background-repeat: no-repeat;
    display: block;
    content: " ";
    -webkit-mask-size: contain;
}

.panel-parent-child-div_1 {
    font-style: italic;
    font-weight: normal;
    cursor: initial;
}

.custom_status_parent {
    border-bottom: 6px solid #ef2637;
}


/* Privacy modal css */

.privacy-popup-rectange {
    /* width: 584px;
  height: 463px; */
    border-radius: 4px;
    background-color: #ffffff;
}

.privacy-popup-image {
    width: 137px;
    height: 85px;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: 16px;
}

.Privacy-terms {
    /* width: 502px; */
    /* height: 38px; */
    font-family: Ubuntu-Medium;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #5a5a5f;
    margin-bottom: 12px;
}

.By-selecting-Yes-I {
    /* width: 384px; */
    /* height: 96px; */
    font-family: Ubuntu-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #717176;
}


/* .By-selecting-Yes-I .text-style-1 {
	font-weight: 500;
	font-family: Ubuntu-Medium
} */

.center {
    margin-left: auto;
    margin-right: auto;
}

.privacyLinkRectange {
    /* width: 584px; */
    /* height: 200px;
	border-radius: 4px; */
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 32px;
}

.privacyLinkRectange .Text {
    text-align: center;
    /* width: 159px; */
    height: 24px;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #f22230;
    text-decoration: none;
}

.Text:hover {
    color: #f22230;
    text-decoration: none;
}

.Text:visited {
    color: #f22230;
    text-decoration: none;
}

.right {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.Box_Yes {
    min-width: 184px;
    height: 48px;
    border-radius: 4px;
    border: solid 1px #ef2637;
    background-color: #ef2637;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0px 20px;
}

.Box_No {
    min-width: 184px;
    height: 48px;
    border-radius: 4px;
    border: solid 1px #ef2637;
    background-color: #ffffff;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #bf1e2c;
    padding: 0px 20px;
}

.Box_Yes_xs {
    height: 48px;
    border-radius: 4px;
    border: solid 1px #ef2637;
    background-color: #ef2637;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0px 20px;
}

.Box_No_xs {
    height: 48px;
    border-radius: 4px;
    border: solid 1px #ef2637;
    background-color: #ffffff;
    font-family: Ubuntu-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #bf1e2c;
    padding: 0px 20px;
}

.Box_No .close_button {
    color: #ef2637;
}

.close_button_div {
    display: flex;
    justify-content: center;
}

.code {
    /* width: 384px; */
    height: 30px;
    font-family: Ubuntu-Medium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #4195b9;
    margin-bottom: 32px;
}

.callCenterNumber {
    color: #ef2637;
}

.span-width {
  display: inline-block;
  width: 80%;
}

/* Privacy modal css end */

.horizontalScrollbardisable {
    overflow-x: hidden;
}

.msgBannerTitle {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
}

.msgBannerbody {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
}

.custom-header-bpost .custom-caret {
    background: url('../images/Dark-Dropdown-Arrow.png');
    width: 7px;
    height: 4px;
    display: inline-block;
    margin-left: 5px;
    background-size: cover;
    vertical-align: middle;
}

.custom-header-bpost ul li a {
    width: 100%;
    text-align: left;
}

.custom-header-bpost .custom_breadcrumb_two .breadcrumb>li+li:before {
    color: #5a5a5f;
    content: "";
    width: 20px;
    height: 20px;
    background-image: url('../images/chevron-right.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 15px;
}

.custom-header-bpost .custom_breadcrumb_two img {
    width: auto;
    height: auto;
    float: left;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 34px;
}

.lmg_logo{
  width: 150px !important;
  cursor: pointer;
}

.custom-header-bpost .custom_breadcrumb_two ol {
    float: left;
    margin-top: 5px;
    background: transparent;
    padding-top: 40px;
}

.custom-header-bpost .custom_breadcrumb_two .Track-parcel a {
    width: 77px;
    height: 20px;
    line-height: 1.43;
    color: #ef2637;
    font-size: 12px;
    /* font-family: 'OpenSans'; */
    text-decoration: underline;
}

.text-sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  bottom: -.25em;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .open-button-mobile img {
        margin-left: -10px;
    }
}

button:focus {
  outline: none;
}
